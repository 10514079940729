<template>
    <div>
        <div class="admin-content">
            <RECEnrollApplication v-show="state.isEnrollApplication" :formInfo="state.formShowItems" :isPreview="state.parameter.isPreview" :recInfo="state.parameter"></RECEnrollApplication>
            <RECInputCareers v-show="state.isInputCareers" :formInfo="state.formShowItems" :isPreview="state.parameter.isPreview" :recInfo="state.parameter"></RECInputCareers>
            <RECEtc v-show="state.isEtc" :formInfo="state.formShowItems" :isPreview="state.parameter.isPreview" :recInfo="state.parameter"></RECEtc>
            <RECInterview v-show="state.isEssay" :formInfo="state.formShowItems" :isPreview="state.parameter.isPreview" :recInfo="state.parameter"></RECInterview>
        </div>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import router from '@/router';
import { form } from '@/modules/recruit/REC_RC0110D.js';
import RECEnrollApplication from '@/components/REC_EnrollApplication.vue';
import RECInputCareers from '@/components/REC_InputCareers.vue';
import RECEtc from '@/components/REC_Etc.vue';
import RECInterview from '@/components/REC_Interview.vue';
export default {
    components: { RECEnrollApplication, RECInputCareers, RECEtc, RECInterview }
    , props: [
        // 양식 정보
        'Seq'

        // 미리보기 여부
        , 'FORM_NO'

        // 지원자 정보
        , 'APCT_SEQ'
    ]
    , setup(props, { emit }) {
        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();

        // 이력서 등록
        const RECEnrollApplication = ref(null)

        // 경력서 등록
        const RECInputCareers = ref(null)

        // state
        var state = reactive({

            dataTable: {

                // 양식 정보
                formItems: new Array()
            }

            , parameter: {

                // 양식 번호
                FORM_NO: !!props.FORM_NO ? props.FORM_NO : null

                // 미리보기 여부
                , Seq: !!props.Seq ? props.Seq : 'N'

                // 미리보기 여부
                , isPreview: !!props.isPreview ? props.isPreview : 'N'

                // 미리보기 여부
                , APCT_SEQ: !!props.APCT_SEQ ? props.APCT_SEQ : null
            }

            // 기본정보 등록 여부
            , isEnrollApplication: true

            // 이력서 등록 여부
            , isInputCareers: false

            // 어학/자격/기타 사항 등록 여부
            , isEtc: false

            // 자기소개서 등록 신입 여부
            , isEssay: true

            // 현재 화면 정보
            , pageInfo: ''

            , formShowItems: {

                // 현재연봉
                isShowCurntSalAmt: false

                // 희망연봉
                , isShowHopeSalAmt: false

                // 성격상 장/단점
                , isShowPsnltyCont: false

                // 병역사항
                , isShowAmy: false

                // 학력사항
                , isShowSchool: false

                // 자격증/수상내역
                , isShowCertification: false

                // 외국어
                , isShowForeignLang: false

                // 첨부파일
                , isShowFile: false

                // 경력(전체)
                , isShowCarrer: false

                // 자기소개서(경력)
                , isShowCarrerEssay: false

                // 자기소개서(신입)
                , isShowNewEssay: false
            }
        });

        // 메소드
        const methods = {

            // 양식 상세 조회
            getFormDetail: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                console.log('state.parameter.FORM_NO', state.parameter.FORM_NO)

                // 상세화면 formNo로 데이터테이블 세팅
                form.getFormDetail(`${state.parameter.FORM_NO}`).then(result => {

                    state.dataTable.formItems = result.Table1.rows;

                }).finally(() => {

                    state.dataTable.formItems.forEach(row => {
                        row.ARTCL_USE_YN = row.ARTCL_USE_YN === 'Y' ? true : false;

                        switch(row.FORM_CONT_CD) {
                            // 현재연봉
                            case 'RC005105':
                                state.formShowItems.isShowCurntSalAmt = row.ARTCL_USE_YN
                                break
                            // 희망연봉
                            case 'RC005110':
                                state.formShowItems.isShowHopeSalAmt = row.ARTCL_USE_YN
                                break
                            // 성격상 장/단점
                            case 'RC005115':
                                state.formShowItems.isShowPsnltyCont = row.ARTCL_USE_YN
                                break
                            // 병역사항
                            case 'RC005120':
                                state.formShowItems.isShowAmy = row.ARTCL_USE_YN
                                break
                            // 학력사항
                            case 'RC005125':
                                state.formShowItems.isShowSchool = row.ARTCL_USE_YN
                                break
                            // 자격증/수상내역
                            case 'RC005130':
                                state.formShowItems.isShowCertification = row.ARTCL_USE_YN
                                break
                            // 외국어
                            case 'RC005135':
                                state.formShowItems.isShowForeignLang = row.ARTCL_USE_YN
                                break
                            // 첨부파일
                            case 'RC005140':
                                state.formShowItems.isShowFile = row.ARTCL_USE_YN
                                break
                            // 경력(전체)
                            case 'RC005205':
                                state.formShowItems.isShowCarrer = row.ARTCL_USE_YN
                                break
                            // 자기소개서(경력)
                            case 'RC005305':
                                state.formShowItems.isShowCarrerEssay = row.ARTCL_USE_YN
                                break
                            // 자기소개서(신입)
                            default:
                                state.formShowItems.isShowNewEssay = row.ARTCL_USE_YN
                                break
                        }
                    })

                    if(!!state.formShowItems.isShowCertification || !!state.formShowItems.isShowForeignLang || !!state.formShowItems.isShowFile){
                        state.isEtc = true
                    }

                    if(!!state.formShowItems.isShowCarrer){
                        state.isInputCareers = true
                    }

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    emit("isOpen", true)
                });
            }
        };

        // 이벤트 핸들러
        const eventHandler = {

            // 미리보기 닫기 클릭 이벤트
            previewCloseClick: () => {

                router.push({ name: 'REC_RC0300R' });
            } 
        };

        onMounted(() => {

            // 채용 입사지원서 양식 목록 초기조회
            methods.getFormDetail();
        });

        return { 
            state
            , methods
            , eventHandler
            , RECEnrollApplication
            , RECInputCareers
            , RECEtc
            , RECInterview
            , props 
        };
    }
}
</script>